
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsb345e9e78eea6b24e7fb20e8c46db16een_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'b345e9e78eea6b24e7fb20e8c46db16e', flattenObject(nsb345e9e78eea6b24e7fb20e8c46db16een_US), true, true);
        
            import nsb345e9e78eea6b24e7fb20e8c46db16een from './en.yaml'
            i18n.addResourceBundle('en', 'b345e9e78eea6b24e7fb20e8c46db16e', flattenObject(nsb345e9e78eea6b24e7fb20e8c46db16een), true, true);
        
        export default 'b345e9e78eea6b24e7fb20e8c46db16e';
    