// @flow
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import styles from './styles.module.scss'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'
import AbsoluteTime from 'components/AbsoluteTime'
import Countdown from 'components/Countdown'

type Props = {
  date: Date
};

class TopBar extends Component<Props, { formattedDate: { [string]: string } }> {
    static defaultProps = {
        date: new Date()
    };

    render () {
        const { t, theme } = this.props

        return (
            <>
                <div className={styles.container} style={{ backgroundColor: theme.background }}>
                        <div className={styles.expiryText} style={{ color: theme.color }}>
                            <span>{t('event_expiry')} &nbsp; <AbsoluteTime dateFormat='long' date={this.props.date} /></span>
                            <Countdown date={this.props.date} />
                        </div>
                </div>

                <div className={styles.spacer} />
            </>
        )
    }
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(TopBar))
