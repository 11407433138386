// @flow
import * as React from 'react'
import cn from 'classnames'

import { Store } from 'Store.js'
import { withTranslation, Trans } from 'react-i18next'
import { Percent } from 'i18n/NumberFormatter'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

function Rates (props) {
    const { theme } = props
    const { state } = React.useContext(Store)
    const incentives = state.campaign.incentives.manufacturer
    return (
        <div className={styles.rates}>
            <h3 className={styles.ratesTitle} style={{ color: theme.titleColor }}>
                <Trans i18nKey="rates">
                    Don't forget about<br/>our <strong>Rates</strong> as low as
                </Trans>
            </h3>
            <span className={cn(styles.ratesValue, { [styles.smallValues]: props.small })} style={{ color: theme.valueColor }}>{Percent(state.locale).format((Number.parseFloat(incentives.interestRate).toPrecision(3) / 100) || 0)}</span>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Rates))
