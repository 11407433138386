// @flow
import * as React from 'react'
import { withRouter } from 'react-router-dom'

import { Store } from 'Store'

import Hero from 'components/Hero'
import RegisterGroup from 'components/RegisterGroup'

import styles from './styles.module.scss'

type Props = {
    location: Location
}

export default withRouter((props: Props) => {
    const { dispatch } = React.useContext(Store)

    React.useMemo(() => props.history.listen(() => {
        if (window.location.pathname.split('/').length === 2) {
            dispatch({
                type: 'SET_LEAD',
                payload: null
            })
        }
    }), [props.history, dispatch])

    return (
        <>
            <div className={styles.registerContainer}>
                <Hero />

                <div className={styles.registerGroupDesktop}>
                    <RegisterGroup />
                </div>
            </div>
        </>
    )
})
