
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns06b7235ea05d4a8fd2ba076978074c17en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '06b7235ea05d4a8fd2ba076978074c17', flattenObject(ns06b7235ea05d4a8fd2ba076978074c17en_US), true, true);
        
            import ns06b7235ea05d4a8fd2ba076978074c17en from './en.yaml'
            i18n.addResourceBundle('en', '06b7235ea05d4a8fd2ba076978074c17', flattenObject(ns06b7235ea05d4a8fd2ba076978074c17en), true, true);
        
        export default '06b7235ea05d4a8fd2ba076978074c17';
    