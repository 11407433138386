import * as React from 'react'
import withTheme from 'hoc/withTheme'
import { Store } from 'Store.js'
import { Currency } from 'i18n/NumberFormatter'
import transDomain from './translations/index.translations'

import supportedThemes from './themes/__supportedThemes.js'
import { withTranslation, Trans } from 'react-i18next'

import COSTCO from './images/costco.png'

import styles from './styles.module.scss'

const CostcoVoucher = ({ t, theme }) => {
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const value = currencyFormatter.format(500)

    if (theme.hidden) return null

    return (
        <div className={styles.costco}>
            <h3 className={styles.costcoInfo}>
                <Trans t={t} i18nKey={'voucher_title'}>
                    Eligible Costco Members Receive <strong><br />Costco Member Pricing<br /> + {{ value }} Costco Shop Card</strong>
                </Trans>
            </h3>
            <div
                className={styles.img}
                style={{ backgroundImage: `url(${COSTCO})` }}
            />
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(CostcoVoucher))
